import * as React from 'react'
import {
  Redirect,
  Route,
  Switch,
  BrowserRouter,
} from 'react-router-dom'

import Dpayment from './pages/dpayment'
import DpaymentOk from './pages/dpayment/ok'
import DpaymentNg from './pages/dpayment/ng'

import AuPayment from './pages/aupayment'
import AuPaymentOk from './pages/aupayment/ok'
import AuPaymentNg from './pages/aupayment/ng'
import softbankpayment from './pages/softbankpayment'
import SoftbankPaymentOk from './pages/softbankpayment/ok'
import SoftbankPaymentNg from './pages/softbankpayment/ng'

export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect from="/index.html" to="/" />
        <Route exact={true} path="/dpayment" component={Dpayment} />
        <Route exact={true} path="/dpayment/ok" component={DpaymentOk} />
        <Route exact={true} path="/dpayment/ng" component={DpaymentNg} />

        <Route exact={true} path="/aupayment" component={AuPayment} />
        <Route exact={true} path="/aupayment/ok" component={AuPaymentOk} />
        <Route exact={true} path="/aupayment/ng" component={AuPaymentNg} />

        <Route exact={true} path="/softbank_payment" component={softbankpayment} />
        <Route exact={true} path="/softbank_payment/ok" component={SoftbankPaymentOk} />
        <Route exact={true} path="/softbank_payment/ng" component={SoftbankPaymentNg} />
      </Switch>
    </BrowserRouter>
  )
}
