import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

import { utilityStyles } from './utilityStyles'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,600,700|Roboto:400,700&display=swap');
  *:not(.material-icons) {
    font-family: 'Roboto', 'Noto Sans JP', sans-serif !important;
  }
  ${reset}
  body {
    margin: 0;
  }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }
  *:focus {
    outline: none;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  h2 {
    font-size: 18px;
    font-weight: bold;
  }
  p {
    line-height: 1.6;
    margin-bottom: 20px;
  }
  .content-inner {
    padding: 0 16px;
  }
  .gm-fullscreen-control,
  .gmnoprint {
    display: none;
  }
  ${utilityStyles()}
`

export default GlobalStyle
