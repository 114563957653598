import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Router from './Router'
import GlobalStyle from './styles/globalStyle'

import theme from './styles/theme'

export default () => (
  <MuiThemeProvider theme={theme}>
    <GlobalStyle />
    <Router />
  </MuiThemeProvider>
)
