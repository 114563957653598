import { css } from 'styled-components';

export const utilityStyles = () => css`
  .u-fz-10 {
    font-size: 1rem !important;
  }
  .u-fz-12 {
    font-size: 1.2rem !important;
  }
  .u-fz-14 {
    font-size: 1.4rem !important;
  }
  .u-fz-16 {
    font-size: 1.6rem !important;
  }
  .u-fz-18 {
    font-size: 1.8rem !important;
  }
`;
