import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#00ABD8',
      dark: '#32497B',
      contrastText: '#ffffff'
    },
    text: {
      primary: '#000000',
      disabled: '#888888'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
  typography: {
    fontSize: 13,
    htmlFontSize: 13
  }
})

export default theme
