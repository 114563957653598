import * as React from 'react'
import {
  Container,
  Box,
  Button,
} from '@material-ui/core'

import AppBar from '../../components/AppBar'
import { getParam } from '../../methods'

export default () => {
  const [redirectUrl, setRedirectUrl] = React.useState('')
  const [accessId, setAccessId] = React.useState('')
  const [token, setToken] = React.useState('')

  const handleSubmit = () => {
    document.forms[0].submit()
  }

  React.useEffect(() => {
    const startUrl = decodeURI(getParam('startUrl'))
    setRedirectUrl(startUrl)

    const accessId = decodeURI(getParam('accessId'))
    setAccessId(accessId)

    const token = decodeURI(getParam('token'))
    setToken(token)
  }, [])

  return (
    <div>
      <AppBar payType='ソフトバンク決済' />

      <Box
        py={3}
        textAlign="center"
      >
        <Container>
          <p>ソフトバンク決済の利用承諾画面へ遷移します。</p>

          <form
            action={redirectUrl}
            method="post"
            id="myForm"
          >
            <input type="hidden" name="AccessID" value={accessId} />
            <input type="hidden" name="Token" value={token} />
          </form>

          <Button
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            style={{
              borderRadius: '24px'
            }}
            onClick={handleSubmit}
          >
            利用承諾画面に進む
          </Button>
        </Container>
      </Box>
    </div>
  )
}
