export class DynamicLinks {
  static DPAYMENT_PAYMENT_COMPLETE: string = 'https://mobile.i-kasa.com/xi6d';
  static DPAYMENT_PAYMENT_CANCEL: string = 'https://mobile.i-kasa.com/aedM';

  static AU_PAYMENT_COMPLETE: string = 'https://mobile.i-kasa.com/wT16';
  static AU_PAYMENT_CANCEL: string = 'https://mobile.i-kasa.com/vBLV';

  static SOFTBANK_PAYMENT_COMPLETE: string = 'https://mobile.i-kasa.com/kr6g';
  static SOFTBANK_PAYMENT_CANCEL: string = 'https://mobile.i-kasa.com/yoxH';
 }
